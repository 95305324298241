<template>
    <div class="modal-wrapper">
        <div class="modal-container">
            <div class="modal-title">
                <h3>스테이션 선택</h3>
                <button @click="closeStationModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon"
                        style="width: 12px;" /></button>
            </div>
            <div class="modal-input">
                <div class="modal-search">
                    <span>검색조건</span>
                    <select v-model="keywordType">
                        <option value="all">{{ $t("search-keyword-all") }}</option>
                        <option value="stationName">스테이션명</option>
                        <option value="droneName">드론명</option>
                        <option value="userName">{{ $t("mission-data-way-registrant") }}</option>
                    </select>
                    <input type="text" v-model="keyword" @keydown.enter="search()" style="width: 375px;" />
                </div>
                <div class="buttonWrap">
                    <button @click="getStationList">
                        {{ $t("btn-searching") }}
                    </button>
                    <button @click="drawStationMarker">
                        {{ $t("btn-reset") }}
                    </button>
                </div>
            </div>
            <div class="modal-content">
                <div class="map">
                    <Map ref="map" style="width: 100%; height: 100%; border-radius:5px;" :loadMapName="loadMap"
                        :isLeftButtonPosition="false" />
                </div>
                <div class="modal-list">
                    <div class="modal-list-title">
                        <h4>스테이션 목록</h4>
                        <h5>총<span>{{ stations.length }}</span>곳</h5>
                    </div>
                    <ul>
                        <li @click="selectStation(index)" :class="{ active: activeIndex === index }"
                            v-for="(station, index) in stations" :key="station.device.managerData.refDeviceId">
                            <h4>{{ station.device.deviceName }}</h4>
                            <p class="m-b-5"><img src="@/assets/images/icon_svg/ic_b_drone.png" alt="icon" />{{
                                station.children.deviceName }}</p>
                            <p><img src="@/assets/images/icon_svg/ic_b_drone.png" alt="icon" />위도: {{station.device.managerData.lat || 0}} 경도: {{station.device.managerData.lng || 0}}</p> 
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal-btn">
                <button @click="saveStation" class="btn-save">확인</button>
                <button @click="closeStationModal">닫기</button>
            </div>
        </div>
    </div>
</template>

<script>
import Map from '@/components/map/Map.vue';
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import { fetchStationDeviceDockList } from "@/api/station/stationDevice";

export default {
    name: "StationModal",
    props: {
        eventId: {
            type: String,
            required: true
        },
    },
    components: {
        Map
    },
    data() {
        return {
            activeIndex: null,
            loadMap: 'kakao',
            showWaySelectModal: false,
            stations: [],
            keyword: '',
            keywordType: 'all',
        }
    },
    created() {
        this.getStationList()
    },
    mounted() {

    },
    methods: {
        getStationList() {
            const params = {
                eventId: this.eventId
            }
            fetchStationDeviceDockList(params).then((res) => {
                if (res.status == 200) {
                    this.stations = res.data.data.content
                    console.log(this.stations)
                    this.drawStationMarker()
                    
                }
            });

            // 응답오고 지도상에 표시

        },

        // 리스트에서 스테이션 셀랙트
        selectStation(selectedIndex) {
            // 셀렉트에서 index 찾아서 반짝하게.
            this.activeIndex = selectedIndex
            this.drawStationMarker(selectedIndex)
            
            const lng = this.stations[this.activeIndex].device.managerData.lng
            const lat = this.stations[this.activeIndex].device.managerData.lat
            if (lng === null || lat === null || lng === undefined || lat === undefined || lng === 0 || lat === 0 ) {
                alert("스테이션 위치를 찾을수없습니다.")
            } else {
                this.$refs.map.onLngLatToMoveCenter(lng, lat)
            }
        },
        drawStationMarker(selectIndex = null) {
            if (this.stations.length < 0) {
                return
            }
            if (this.$refs.map === undefined) {
                return
            }

            const mapDataType = MapDataType.ID_SCHEDULE_STATION

            this.stations.forEach((item, index) => {
                // const index = Number(item.device.managerData.stationDeviceId)
                const mapMarkerData = new MapMarkerData();

                mapMarkerData.id = index;
                mapMarkerData.name = item.device.managerData.stationDeviceId;
                // mapMarkerData.lng = item.lng;
                // mapMarkerData.lat = item.lat;
                mapMarkerData.lng = 127.1207235830093 + index;
                mapMarkerData.lat = 37.54941346481594 + index;
                if (selectIndex === index) {
                    mapMarkerData.isMain = true
                }

                if (this.$refs.map.onIsMarker(mapDataType, index)) {
                    this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
                } else {
                    this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
                }
            })
            // this.$refs.map.onLngLatToMoveCenter(this.stations[this.activeIndex].managerData.lng, this.stations[this.activeIndex].device.managerData.lat)

        },
        search() {
            console.log("대충 검색내용")
        },
        closeStationModal() {
            this.$emit('isStationModal', false)
        },
        saveStation() {
            this.$emit('saveStation', this.stations[this.activeIndex])
        }

    }
}
</script>